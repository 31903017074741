import { useState, useEffect } from 'preact/hooks';
import style from './style.css';

const LessonItem = ({
	title,
	image,
	video_url,
	video_duration,
	updated_at,
}) => (
	<div class={style.lesson_card}>
		<img class={style.lesson_icon} src={image} alt={title} />
		<div>
			<strong>{title}</strong>
			<div>
				<div>
					{video_duration}
				</div>
				<div>
					{updated_at}
				</div>
			</div>
		</div>
		<a href={video_url} target="_blank">播放</a>
	</div>
)

export default LessonItem