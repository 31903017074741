import { useState, useEffect } from 'preact/hooks';

// import Header from './components/header';
import ClassCard from './components/class';
import LessonCard from './components/lesson';
// import groups from './data/groups.json'
import style from './style/index.css';

const App = () => {
    const classList = [
        {
            "id": 924,
            "icon": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/a3d8272f5d76e5973bf7308f22e7b01d.png",
            "name": "TD【VIP学习天团】"
        },
        {
            "id": 1333,
            "icon": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/e42a8b0c84337ea10570a87516d5e8f9.jpg",
            "name": "周期结构—赵伟老师秋季博股学员班"
        },
        {
            "id": 257,
            "icon": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/1f17b93620bdf228f1697cd69f2b38fe.png",
            "name": "TD学习营第八期"
        },
        {
            "id": 533,
            "icon": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/12868abeb345eebd27ddd09e875b5e36.webp",
            "name": "WH班级"
        },
        {
            "id": 698,
            "icon": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/d9f4551cde4c80d15c4575ff4989fb3a.webp",
            "name": "WH网员高级实战班hlw"
        },
        {
            "id": 1660,
            "icon": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/29310a0dc925908d11970b338da88f2f.png",
            "name": "WH网员专享-童文老师班级群"
        },
        {
            "id": 1699,
            "icon": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/b3b1e018329a941c28cb83b989f8372b.jpg",
            "name": "跨年行情博股会员体验班"
        },
        {
            "id": 822,
            "icon": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/bd8c716fd1b092668d64dbaba5588a1d.png",
            "name": "《主力操盘全景图》实验班"
        },
        {
            "id": 1336,
            "icon": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/d250185b8aa9e0d00a4f4b79b3193327.png",
            "name": "登龙门—赵维剑老师秋季博股班级群"
        },
        {
            "id": 1495,
            "icon": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/f5725df741d8edc444495a8353b3465b.jpg",
            "name": "技能训练营"
        },
        {
            "id": 982,
            "icon": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/2ca16f2909bf39876465441a3b7833ff.png",
            "name": "WH-TD左侧技术交流群"
        }
    ]

	const [openClassId, setOpenClassId] = useState(null)
	const [lessonList, setLessonList] = useState([])

	console.log(openClassId)
	useEffect(() => {
		if (!openClassId) {
			setLessonList([])
			return
		}
		if (openClassId && lessonList.length === 0) {
			fetch(`/data/${openClassId}.json`)
				.then(res => res.json())
				.then(data => {
					setLessonList(data.data.data)
				})
		}
	}, [openClassId])
	return (
		<div id="app">
			{/* <Header /> */}
			<main>

				{
					lessonList?.length > 0 ? (
						<section>
							{
								lessonList.map(item => (
									<LessonCard
										key={item.id}
										{...item}
									/>
								))
							}
							<button onClick={() => setOpenClassId(null)}>关闭</button>
						</section>
					) : (
						classList.map(item => (
							<div
								key={item.id}
								onClick={() => setOpenClassId(item.id)}
							>
								<ClassCard {...item} />
							</div>
						))
					)
				}
			</main>
		</div>
	)
};

export default App;
