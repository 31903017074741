import { useState, useEffect } from 'preact/hooks';
import style from './style.css';

// {
// 	"id": 71716,
// 	"classId": 1333,
// 	"jwcode": 30000832,
// 	"title": "知识系列课4：V型突破模型",
// 	"image": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/default/cc6a11dfed4c8fb8ef15e8cf7a1e7c3f.jpg",
// 	"video_url": "https://lfjf.rzfwq.com/jtzy/Product/pcjingwang/uploads/videos/5ccd33722944dd24d3856f40c1893886.mp4",
// 	"video_duration": "58分钟35秒",
// 	"status": 1,
// 	"created_at": "2023-12-08 20:15:24",
// 	"updated_at": "2023-12-08 20:15:24"
// },
console.log(style)
const Class = ({
	id,
	icon,
	name,
	total,
	updated_at,
}) => {
	return (
		<div
			class={style.class_card}
			style={{
				backgroundImage: `url(${icon})`,
			}}
		>
			<div class={style.classTitle}>
				{name}
			</div>
		</div>
)
};

export default Class;
